import React from 'react';
import { FaPlane, FaHotel, FaShuttleVan, FaPassport, FaCheck } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { getHotelImages, makkahHotels, madinahHotels } from '../../Utilities/UtilityFunctions';
import airPlane from "../../assets/homepage/popularPackage/airFlight.png";
import hotels from "../../assets/homepage/popularPackage/hotel.png";
import transport from "../../assets/homepage/popularPackage/transport.png";
import saudiVisa from "../../assets/homepage/popularPackage/visa.png";

const PackageCardHome = ({ packageData }) => {
    const {
        package_name,
        company_detail: { company_name } = {},
        start_date,
        end_date,
        hotel_info_detail = [],
        is_visa_included,
        is_return_flight_included,
        is_flight_included,
        is_transport_included,
        package_base_cost,
        discounted_cost,
        is_airport_reception_included,
        is_tour_guide_included,
        is_insurance_included,
        is_breakfast_included,
        is_lunch_included,
        is_dinner_included,
        depart_city,
    } = packageData;

    // Determine the number of days and nights
    const totalDays = Math.floor((new Date(end_date) - new Date(start_date)) / (1000 * 3600 * 24));
    const totalNights = totalDays - 1;
    const daysAndNightsLabel = `${totalDays} Days ${totalNights} Nights`;

    // Determine departure city (fallback to 'Islamabad')
    const departCity = depart_city || 'Islamabad';

    // Dynamic inclusions array with new conditions
    const inclusions = [
        { label: daysAndNightsLabel, included: true },
        { label: `Departs from ${departCity}`, included: true },
        ...(is_tour_guide_included ? [{ label: 'Makkah & Madinah Ziyarah', included: true }] : []),
    ];

    // Determine hotel label based on availability
    let hotelLabel = 'No Hotel';

    if (hotel_info_detail.length === 1) {
        hotelLabel = '1 Hotel'; // Show '1 Hotel' if there's only one hotel
    } else if (hotel_info_detail.length === 2) {
        hotelLabel = '2 Hotels'; // Show '2 Hotels' if there are exactly two hotels
    }

    return (
        <div className="rounded-lg lg:w-[270px] xl:w-auto h-[470px] overflow-hidden transition-transform duration-300 hover:scale-105 flex flex-col">
            {/* Top Image Section with Swiper */}
            <div className="relative w-full h-[270px] overflow-hidden rounded-lg">
                {hotel_info_detail.length > 0 ? (
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        loop
                        autoplay={{ delay: 3000, disableOnInteraction: false }} // Autoplay added here
                        pagination={false}
                        navigation={false}
                        modules={[Autoplay]} // Ensure the Autoplay module is included
                    >
                        {hotel_info_detail.map(hotel => {
                            const images = getHotelImages(hotel.hotel_name, [...makkahHotels, ...madinahHotels]);
                            return images.length > 0 ? (
                                images.map((image, idx) => (
                                    <SwiperSlide key={`${hotel.hotel_name}-${idx}`}>
                                        <div className="relative w-full h-full">
                                            <img
                                                src={image}
                                                alt={hotel.hotel_name}
                                                className="object-cover w-full h-full"
                                                style={{
                                                    width: '100%',
                                                    height: '270px',
                                                }}
                                            />
                                            {/* Gradient Overlay */}
                                            <div
                                                className="absolute inset-0"
                                                style={{
                                                    background: 'linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)'
                                                }}
                                            ></div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <SwiperSlide key={hotel.hotel_name}>
                                    <div className="relative w-full h-full ">
                                        <img
                                            src="https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D"
                                            alt="Default Hotel"
                                            className="object-cover w-full h-full"
                                            style={{
                                                width: '100%',
                                                height: '270px',
                                            }}
                                        />
                                        {/* Gradient Overlay */}
                                        <div
                                            className="absolute inset-0"
                                            style={{
                                                background: 'linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)'
                                            }}
                                        ></div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                ) : (
                    <div className="relative w-full h-full">
                        <img
                            src="default-image-url.jpg"
                            alt="Default Package"
                            className="object-cover w-full h-full"
                            style={{
                                width: '100%',
                                height: '270px',
                            }}
                        />
                        {/* Gradient Overlay */}
                        <div
                            className="absolute inset-0"
                            style={{
                                background: 'linear-gradient(0deg, #0C2A35 0%, rgba(24, 77, 97, 0) 50%)'
                            }}
                        ></div>
                    </div>
                )}
                <div className="absolute bottom-4 left-4 text-start text-white z-50">
                    <h3 className="font-[500] text-lg lg:text-2xl">{package_name}</h3>
                    <p className="text-brandGold font-[600] text-sm">By {company_name}</p>
                    <p className="text-sm font-light">
                        {new Date(start_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })} till {new Date(end_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                    </p>
                </div>
            </div>

            {/* Lower Section with Border */}
            <div className="bg-white border-[1px] border-custom-gray rounded-b-lg px-6 pb-4 flex flex-col flex-1 justify-between">
                {/* Features Row Section */}
                <div className="flex px-2 justify-between mt-4 mb-6 text-gray-500 text-xs">
                    <div className="flex items-center flex-col">
                        <img src={airPlane} alt='airPlane' className={`${is_flight_included ? 'text-brandGold' : 'text-mediumBlue'} mb-1`} />
                        <span className="text-xs text-gray-500">
                            {is_flight_included && is_return_flight_included ? 'Round Trip' : is_flight_included ? 'One Way' : 'No Flight'}
                        </span>
                    </div>
                    <div className="flex items-center flex-col">
                        <img src={hotels} className="text-brandGold mb-1" />
                        <span className="text-xs text-gray-500">{hotelLabel}</span>
                    </div>
                    <div className="flex items-center flex-col">
                        <img src={transport} className={`${is_transport_included ? 'text-brandGold' : 'text-mediumBlue'} mb-1`} />
                        <span className="text-xs text-gray-500">Transport</span>
                    </div>
                    <div className="flex items-center flex-col">
                        <img src={saudiVisa} className={`${is_visa_included ? 'text-brandGold' : 'text-mediumBlue'} mb-1`} />
                        <span className="text-xs text-gray-500">Visa</span>
                    </div>
                </div>

                {/* Inclusions Section */}
                <div className="space-y-2 mb-4">
                    {inclusions.map((item, index) => (
                        <div key={index} className="flex items-center gap-4 font-light text-secondaryGray">
                            <FaCheck className="text-brandGold" />
                            <span className="text-sm">{item.label}</span>
                        </div>
                    ))}
                </div>

                {/* Price Section - Always at the Bottom */}
                <div className="flex justify-between items-center mt-auto">
                    {discounted_cost ? (
                        <>
                            <p className="text-gray-400 line-through text-sm line-height-0-lg">PKR {package_base_cost}</p>
                            <p className="text-darkBlue text-end lg:text-lg xl:text-xl font font-medium line-height-0-lg">
                                PKR {discounted_cost} <span className="text-sm font-normal text-gray-500">Per person</span>
                            </p>
                        </>
                    ) : (
                        <p className="text-darkBlue text-end lg:text-lg xl:text-xl font font-medium line-height-0-lg">
                            PKR {package_base_cost} <span className="text-sm font-normal text-gray-500">Per person</span>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PackageCardHome;
