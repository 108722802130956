import React from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import bgImage from "../../../assets/homepage/discountSection/bgImage.png";
import bgshade from "../../../assets/bgShade.png";
const DiscountSection = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleNavigate = () => {
    navigate("/listing-page"); // Navigate to the desired route
  };

  return (
    <div
      className="w-full bg-cover bg-center"
      style={{
        backgroundImage: `
          url(${bgshade}), 
          url(${bgImage})`,
      }}
    >
      <div className="w-all-sections-width mx-auto py-s10 md:py-12 lg:py-24 space-y-6">
        <p className="text-brandGold text-[24px] font-600">
          Extra 30% Off Online
        </p>
        <p className="h-48px text-white">Tailored made Umrah packages</p>
        <p className="text-[16px] font-400 text-white">
          Our professional advisors and agents have crafted the{" "}
          <br className="block xl:hidden" /> perfect itenerary for you. Plan
          your trip with <br className="hidden xl:block" /> our expert agents
        </p>
        <button
          className="mt-6 px-6 py-3 bg-brandGold font-normal text-[16px] text-secondaryBlue rounded-full hover:bg-opacity-90 w-auto"
          onClick={handleNavigate} // Attach the navigate function to the onClick event
        >
          View All Packages
        </button>
      </div>
    </div>
  );
};

export default DiscountSection;
