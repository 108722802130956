import React, { useEffect, useState } from 'react';
import PackageCarousel from './PackageCarousel';
import { getShortPackageDetails } from '../../../api/apiService';
import { MessageDisplay } from '../../../';

const PopularPackages = ({ onStatusChange }) => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackages = async () => {
      // Notify parent that loading has started
      onStatusChange('loading');
      setLoading(true);
      setError(null);

      try {
        // Call updated getShortPackageDetails
        const data = await getShortPackageDetails({
          package_type: 'umrah',
          page: 1,
          page_size: 10,
        });

        if (data && data.results.length > 0) {
          setPackages(data.results); // Directly set the packages
          onStatusChange('success'); // Notify parent of success
        } else {
          setError('No packages available at the moment. Please check back later!');
          onStatusChange('error'); // Notify parent of error
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching packages.');
        onStatusChange('error'); // Notify parent of error
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, [onStatusChange]);

  return (
    <section className="lg:relative mt-s10 md:mt-12 lg:mt-0 bg-white z-10">
      <div className="w-all-sections-width mx-auto lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:absolute lg:-top-[290px]">
        <h2 className="h2 font-bold text-secondaryBlue lg:text-white mb-6 text-start">
          Popular Umrah Packages
        </h2>

        {loading ? (
          <MessageDisplay type="loading" message="Fetching the latest packages for you..." />
        ) : error ? (
          <MessageDisplay type="error" message={error} />
        ) : (
          <PackageCarousel packages={packages} />
        )}
      </div>
    </section>
  );
};

export default PopularPackages;
