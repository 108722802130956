import React from "react";

import logo from "../../assets/header/logo.png";
import facebook from "../../assets/footer/facebook.png";
import instagram from "../../assets/footer/instagram.png";
import twitter from "../../assets/footer/twitter.png";
import youtube from "../../assets/footer/youtube.png";
const Footer = () => {
  const data = [
    {
      text: "Self Betimes",
    },
    {
      text: "Yard Market",
    },
    {
      text: "Hegde Karla",
    },
    {
      text: "Yatırımcı İlişkileri",
    },
    {
      text: "Is İmkanları",
    },
    {
      text: "Mullein Koşulları",
    },
    {
      text: "Basal Himmler",
    },
    {
      text: "Car Tercihleri",
    },
    {
      text: "Autumnal Bulgier",
    },
  ];
  const data1 = [
    {
      imagee: facebook,
    },
    {
      imagee: instagram,
    },
    {
      imagee: twitter,
    },
    {
      imagee: youtube,
    },
  ];
  return (
    <footer>
      <div className="bg-secondaryBlue w-full ">
        <div className="w-all-sections-width mx-auto md:flex justify-between pt-s10 md:pt-0">
          <div className="flex items-center gap-4">
            <img
              src={logo}
              alt="Logo Icon"
              style={{ width: "28.95px", height: "40px" }}
            />
            <span className="text-[28px] font-semibold leading-[40px] text-white">
              HajjUmrah.co
            </span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-s10">
            {data.map((item) => {
              return (
                <div>
                  <p className="text-secondaryGray text-[14px]">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-all-sections-width mx-auto flex justify-between mt-20 pb-8">
          <p className="text-secondaryGray text-[12px]">
            © 1997-2021 Netflix, Inc.i-062d573a0ee099242
          </p>
          <div className="flex gap-4 items-center">
            {data1.map((item) => {
              return (
                <div>
                  <img src={item.imagee} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

