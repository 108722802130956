import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// Page:
export { default as HomePage } from './pages/HomePage/HomePage';

//Its Components:
export { default as Header } from './components/Header/Header';
export { default as Hero } from './pages/HomePage/components/Hero';
export { default as SearchBar } from './components/SearchBar/SearchBar';
export { default as Footer } from './components/Footer/Footer';
export { default as AppDownload } from './components/AppDownload/AppDownload';
export { default as PopularPackages } from './pages/HomePage/components/PopularPackages'; // PopularPackages section
export { default as ServicesSection } from "./components/ServicesSection/ServicesSection";
export { default as DiscountSection } from "./pages/HomePage/components/DiscountSection";
export { default as BrowseByPeople } from "./pages/HomePage/components/BrowseByPeople";
export { default as MessageDisplay } from "./components/MessageDisplay/MessageDisplay";
export { default as PackageCardHome } from "./components/PackageCardHome/PackageCardHome";


//Page:
export { default as ListingPage } from './pages/ListingPage/ListingPage';

//components:
export { default as SortingDiv } from './pages/ListingPage/components/SortingDiv';
export { default as FiltersComponent } from './pages/ListingPage/components/Filters';
export { default as PackageCard } from './pages/ListingPage/components/PackageCard';
export { default as PackageListing } from './pages/ListingPage/components/PackageListing';

