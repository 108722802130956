// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage, ListingPage } from "./"; // Ensure you have imported your pages correctly
import ScrollToTop from "./Utilities/UtilityFunctions.js"; // Import ScrollToTop utility
import './styles/globals.css' // Ensure global styles are imported

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Ensure this is inside the Router */}
      <div>
        <Routes>
          {/* Home Page Route */}
          <Route path="/" element={<HomePage />} />
          {/* Listing Page Route */}
          <Route path="/listing-page" element={<ListingPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
