// src/api/apiService.js
import axios from 'axios';

// Create an axios instance with default headers and base URL
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
        'Content-Type': 'application/json',
    },
});

const handleApiRequest = async (endpoint, params = {}) => {
    try {
        const response = await api.get(endpoint, { params });

        // Check for a successful response
        if (response.status === 200 && response.data) {
            return {
                success: true,
                data: response.data,
            };
        }

        // Handle unexpected response structure
        return { success: false, error: 'Unexpected response format from server.' };
    } catch (error) {
        // Handle specific HTTP error responses
        if (error.response) {
            const status = error.response.status;
            const errorMessages = {
                400: 'Bad Request: Missing or invalid input data.',
                401: 'Unauthorized: Admin permissions required.',
                404: 'Not Found: No data found for the given criteria.',
                500: 'Server Error: Internal server error.',
            };
            return { success: false, error: errorMessages[status] || `Unexpected status code: ${status}` };
        } else if (error.message) {
            // Handle client-side or network errors
            return { success: false, error: error.message };
        }

        // Catch-all for unexpected errors
        return { success: false, error: 'An unknown error occurred. Please try again later.' };
    }
};

const validateParams = (params, requiredFields) => {
    for (const field of requiredFields) {
        if (!params[field]) {
            return `${field} is required.`;
        }
    }
    return null;
};



// Fetch Featured Packages
export const getFeaturedPackages = async ({ package_type, page = 1, page_size = 10 }) => {
    // Validate input parameters
    const validationError = validateParams({ package_type }, ['package_type']);
    if (validationError) {
        return { success: false, error: validationError };
    }

    // Make the API call
    const result = await handleApiRequest('/partner/get_featured_packages/', {
        package_type,
        page,
        page_size,
    });

    // Process and structure the response if successful
    if (result.success) {
        const data = result.data;
        return {
            success: true,
            packages: data.results || [],
            count: data.count || 0,
            next: data.next || null,
            previous: data.previous || null,
        };
    }

    // Return error if API call failed
    return result;
};

//get all packages, and their short details
export const getShortPackageDetails = async ({ package_type, page = 1, page_size = 10 }) => {
    // Validate input parameters
    const validationError = validateParams({ package_type }, ['package_type']);
    if (validationError) {
        return { success: false, error: validationError };
    }

    // Make the API call
    const result = await handleApiRequest('/partner/get_package_short_detail_for_web/', {
        package_type,
        page,
        page_size,
    });

    // Return only the results if the call is successful
    if (result.success) {
        return result.data || [];
    }

    // Return an empty array if API call failed
    return [];
};


//Get all Packages from Search.
export const getPackageDetailByCityAndDate = async ({
    package_type,
    start_date,
    flight_from,
    page = 1,
    page_size = 10,
}) => {
    // Validate required parameters
    const validationError = validateParams(
        { package_type, start_date, flight_from },
        ['package_type', 'start_date', 'flight_from']
    );
    if (validationError) {
        return { success: false, error: validationError };
    }

    // Make the API call
    const result = await handleApiRequest('/partner/get_package_detail_by_city_and_date/', {
        package_type,
        start_date,
        flight_from,
        page,
        page_size,
    });

    // Return only the results if the call is successful
    if (result.success) {
        return result.data || [];
    }

    // Return an empty array if API call failed
    return [];
};

