import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay } from "swiper/modules";

import {
  getHotelImages,
  makkahHotels,
  madinahHotels,
} from "../../../Utilities/UtilityFunctions"; // Import utility function

const PackageCard = ({ data }) => {
  const {
    discounted_cost,
    package_name,
    start_date,
    end_date,
    package_base_cost,
    company_detail,
    hotel_info_detail,
  } = data;

  const [travellers, setTravellers] = useState(1);

  const handleTravellerChange = (type) => {
    setTravellers((prev) => {
      if (type === "increase") return prev + 1;
      if (type === "decrease" && prev > 1) return prev - 1;
      return prev;
    });
  };

  // Fetch hotel images from JSON data using getHotelImages
  const hotelImages = hotel_info_detail
    .map((hotel) =>
      getHotelImages(hotel.hotel_name, [...makkahHotels, ...madinahHotels])
    ) // Pass data as array
    .flat();

  return (
    <div className="bg-white border-[1px] border-custom-gray rounded-lg flex flex-col lg:flex-row p-4 gap-4 hover:shadow-xl transition-transform transform hover:scale-[1.02] duration-300">
      {/* Image Section with Swiper for Carousel */}
      <div className="w-full lg:w-32 h-40 lg:h-32 overflow-hidden rounded-md">
        {hotelImages.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            autoplay={{ delay: 2000, disableOnInteraction: false }} // Autoplay with delay of 3 seconds
            pagination={false}
            navigation={false}
            modules={[Autoplay]} // Ensure Autoplay module is passed here
            className="w-full h-full"
          >
            {hotelImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={package_name}
                  className="w-full h-full object-cover rounded-md"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <img
            src="https://via.placeholder.com/150"
            alt="Default hotel"
            className="w-full h-full object-cover rounded-md"
          />
        )}
      </div>

      {/* Package Details Section */}
      <div className="flex flex-col justify-between flex-grow">
        <div>
          <h3 className="font-normal text-xl">
            {package_name}
          </h3>
          <p className="text-sm font-extralight text-primaryGray">
            {new Date(start_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            to{" "}
            {new Date(end_date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>

          <p className="text-brandGold font-light text-xs mt-1">
            By {company_detail?.company_name || "N/A"}
          </p>
        </div>

        <div>
          <p className="text-xs font-medium text-secondaryGray mt-4">
            TRAVELLERS
          </p>
          <div className="flex items-center mt-1">
            <button
              className="border border-lightGray px-3 py-1  hover:bg-gray-200 font-light hover:scale-110 transition-transform duration-200"
              onClick={() => handleTravellerChange("decrease")}
            >
              -
            </button>
            <span className="border-y border-lightGray px-5 py-1 font-light  hover:bg-gray-200 hover:scale-110 transition-transform duration-200 ">
              {travellers}
            </span>
            <button
              className="border border-lightGray px-3 py-1 font-light  hover:bg-gray-200 hover:scale-110 transition-transform duration-200"
              onClick={() => handleTravellerChange("increase")}
            >
              +
            </button>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="flex flex-col items-end justify-end text-start">
        <p className="text-secondaryGray font-light text-[10.9px]">
          DIRECT FLIGHT FROM ISLAMABAD
        </p>
        <div className="flex flex-col items-end">
          {discounted_cost ? (
            <>
              <p className="text-gray-400 line-through text-sm">
                PKR {package_base_cost.toLocaleString()}
              </p>
              <p className="text-darkBlue text-lg font-normal transition-colors hover:text-brandGold">
                PKR {discounted_cost.toLocaleString()}{" "}
                <span className="text-sm font-normal text-gray-500">
                  / person
                </span>
              </p>
            </>
          ) : (
            <p className="text-brandGold text-lg font-normal transition-colors hover:text-darkBlue">
              PKR {package_base_cost.toLocaleString()} / person
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
