import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilter, FaUndoAlt } from "react-icons/fa";

const FiltersComponent = ({
  onFilterChange,
  clearFilters,
  filters,
  setFilters,
}) => {
  const [departureCity, setDepartureCity] = useState(
    filters.departureCity || null
  );
  const [departureDate, setDepartureDate] = useState(
    filters.departureDate || null
  );
  const [umrahDuration, setUmrahDuration] = useState(
    filters.umrahDuration || null
  );
  const [roomType, setRoomType] = useState(filters.roomType || null);
  const [tripDetails, setTripDetails] = useState(
    filters.tripDetails || {
      tourGuide: false,
      lunchIncluded: false,
      dinnerIncluded: false,
      airportReception: false,
    }
  );

  const handleFilterChange = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };

    // Only call onFilterChange if the update is for departureCity or departureDate
    if (key === "departureCity" || key === "departureDate") {
      onFilterChange(updatedFilters); // This will trigger API call for city and date changes
    } else {
      // Otherwise, just update the filters without causing the API call
      setFilters(updatedFilters);
    }
  };

  const handleTripDetailChange = (key) => {
    const updatedDetails = { ...tripDetails, [key]: !tripDetails[key] };
    setTripDetails(updatedDetails);
    handleFilterChange(key, updatedDetails[key]);
  };

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "8px",
      border: "1px solid rgba(223, 228, 234, 1)",
      padding: "4px",
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isFocused
        ? "rgba(209, 165, 84, 1)" // brandGold on hover
        : "white", // No background color on selected
      color: isSelected ? "black" : "black", // Keep text color black, no change on selected
      fontSize: "14px", // equivalent to text-sm in Tailwind
      fontWeight: "100", // equivalent to font-thin in Tailwind
      cursor: "pointer",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "rgba(107, 114, 128, 1)",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "16px", // equivalent to text-sm in Tailwind
      fontWeight: "100", // equivalent to font-thin in Tailwind
    }),
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-start items-center">
        <button
          onClick={() => {
            setDepartureCity(null);
            setDepartureDate(null);
            setUmrahDuration(null);
            setRoomType(null);
            setTripDetails({
              tourGuide: false,
              lunchIncluded: false,
              dinnerIncluded: false,
              airportReception: false,
            });
            clearFilters();
          }}
          className="flex items-center gap-2 px-6 py-3 bg-white text-black rounded-lg shadow-md hover:bg-gray-100 transition-colors duration-300"
        >
          <FaUndoAlt className="text-black" />
          <span>Clear Filters</span>
        </button>
      </div>

      <div className="bg-custom-gradient rounded-lg border-[1px] border-custom-gray">
        <h3 className="text-black text-lg font-medium flex items-center gap-2 text-[18px] px-7 py-4 border-b-[1px] border-custom-gray">
          Departure
        </h3>
        <div className="p-7 space-y-4">
          <div className="">
            <label className="block text-base text-black mb-2">
              Departure City
            </label>
            <Select
              options={[
                { value: "Islamabad", label: "Islamabad" },
                { value: "Multan", label: "Multan" },
                { value: "Lahore", label: "Lahore" },
              ]}
              styles={customSelectStyles}
              value={departureCity}
              onChange={(selected) => {
                setDepartureCity(selected);
                handleFilterChange("departureCity", selected);
              }}
              placeholder="Select City"
            />
          </div>
          <div className="">
            <label className="block text-base text-black mb-2">
              Departure Date
            </label>
            <DatePicker
              selected={departureDate}
              onChange={(date) => {
                setDepartureDate(date);
                handleFilterChange("departureDate", date);
              }}
              minDate={new Date()}
              placeholderText="Select a date"
              className="w-full block lg:min-w-[340px] min-w-full max-w-full border border-custom-gray rounded-md px-3 py-2 text-base text-black hover:border-gray-400 box-border"
              calendarClassName="custom-datepicker"
              // popperClassName="custom-datepicker-popper"
              popperPlacement="bottom-start"
            />
          </div>

          <div className="">
            <h3 className="text-base text-black mb-2">Umrah Duration</h3>
            <Select
              options={[
                { value: 3, label: "3 Nights" },
                { value: 7, label: "7 Nights" },
                { value: 10, label: "10 Nights" },
              ]}
              styles={customSelectStyles}
              value={umrahDuration}
              onChange={(selected) => {
                setUmrahDuration(selected);
                handleFilterChange("umrahDuration", selected);
              }}
              placeholder="Select # of Days"
            />
          </div>
        </div>
      </div>

      <div className="bg-custom-gradient rounded-lg border-[1px] border-custom-gray">
        <h3 className="text-black text-lg font-medium flex items-center gap-2 text-[18px] px-7 py-4 border-b-[1px] border-custom-gray">
          Room Type
        </h3>
        <div className="space-y-3 p-6 py-4">
          {["Single", "Double", "Triple", "Quad", "Sharing"].map((value) => (
            <div key={value} className="form-group">
              <label className="flex items-center text-black text-base font-normal">
                <input
                  type="radio"
                  name="roomType"
                  value={value}
                  checked={roomType === value}
                  onChange={() => {
                    setRoomType(value);
                    handleFilterChange("roomType", value);
                  }}
                />
                {value}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-custom-gradient rounded-lg border-[1px] border-custom-gray">
        <h3 className="text-black text-lg font-medium flex items-center gap-2 text-[18px] px-7 py-4 border-b-[1px] border-custom-gray">
          Trip Details
        </h3>
        <div className="space-y-3 p-6 py-4">
          {[
            "tourGuide",
            "lunchIncluded",
            "dinnerIncluded",
            "airportReception",
          ].map((key) => (
            <div key={key} className="form-group">
              <label className="flex items-center text-black text-base font-normal">
                <input
                  type="checkbox"
                  checked={tripDetails[key]}
                  onChange={() => handleTripDetailChange(key)}
                />
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FiltersComponent;
