import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import logoIcon from '../../assets/logoIcon.png';

const navLinks = [
  { href: '/', label: 'Home' },
  { href: '/about', label: 'About' },
  { href: '/contact', label: 'Contact' },
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const isHomeRoute = pathname === '/'; // Check if the current route is home

  const renderLinks = (isMobile = false) =>
    navLinks.map(({ href, label }) => (
      <a
        key={href}
        href={href}
        className={`text-[16px] font-medium leading-[24px] ${
          pathname === href ? 'text-brandGold' : isHomeRoute ? 'text-white hover:text-brandGold' : 'text-primaryGray   hover:text-brandGold'
        } ${isMobile ? 'py-4' : ''}`}
        onClick={isMobile ? toggleMenu : null}
      >
        {label}
      </a>
    ));

  return (
    <header className="w-all-sections-width mx-auto flex justify-between items-center py-8">
      {/* Logo */}
      <div className="flex items-center gap-4">
        <img src={logoIcon} alt="Logo Icon" className="w-[28.95px] h-[40px]" />
        <span
          className={`text-[28px] font-medium leading-[40px] tracking-wider ${
            isHomeRoute ? 'text-white' : 'text-black'
          }`}
        >
          HajjUmrah.co
        </span>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex gap-10">{renderLinks()}</nav>

      {/* Mobile Hamburger Icon */}
      <button
        onClick={toggleMenu}
        aria-label="Toggle Menu"
        className={`lg:hidden ${isHomeRoute ? 'text-white' : 'text-black'}`}
      >
        {isMenuOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
      </button>

      {/* Desktop Login/Sign-Up */}
      <div className="hidden lg:flex gap-6">
        <button
          className={`text-[16px] font-medium leading-[24px] ${
            isHomeRoute ? 'text-white hover:text-brandGold' : 'text-primaryGray  hover:text-brandGold'
          }`}
        >
          Login
        </button>
        <button className="px-6 py-2 border border-brandGold text-brandGold rounded-full hover:bg-brandGold hover:text-white">
          Sign Up
        </button>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          transition={{ duration: 0.3 }}
          className={`absolute pb-6 top-20 left-0 w-full ${
            isHomeRoute ? 'bg-black bg-opacity-80 text-white' : 'bg-white text-lightGray'
          } flex flex-col items-center md:hidden z-50`}
        >
          {renderLinks(true)}
          <button
            className={`text-[16px] font-medium leading-[24px] mt-4 ${
              isHomeRoute ? 'hover:text-brandGold' : 'hover:text-brandGold'
            }`}
            onClick={toggleMenu}
          >
            Login
          </button>
          <button
            className="px-6 py-2 border border-brandGold text-brandGold rounded-full mt-4 hover:bg-brandGold hover:text-white"
            onClick={toggleMenu}
          >
            Sign Up
          </button>
        </motion.div>
      )}
    </header>
  );
};

export default Header;
